<template>
    <section class="analytic-section section-gap-30">
        <div class="section-title-wrap d-flex justify-content-between align-content-center">
            <h2 class="section-title">Analytics</h2>
            <analytics-filter @filter="filter"></analytics-filter>
        </div>
        <DashboardStatsLoader v-if="isContentLoading" style="margin-top: 30px"></DashboardStatsLoader>
        <template v-else>
            <div class="graph-contr">
                <div class="graph__control">
                    <div class="graph__control__button relative text-center" v-for="counter in counters">
                        <div class="count__for text-break" >{{$t(countFor)}}</div>
                        <div class="counter__box">
                            <h2 class="count__number">{{counter.count}}</h2>
                        </div>
                        <div class="count__title text-break">{{ $t(counter.label) }}</div>
                    </div>
                </div>
            </div>
<!--                <div class="counter-box">-->
<!--                    <div class="row row-cols-md-3">-->
<!--                        <div class="col" v-for="counter in counters">-->
<!--                            <div class="counter__card">-->
<!--                                <div class="counter__card__icon gradient-danger">-->
<!--                                    <i class="eicon" :class="counter.class"></i>-->
<!--                                </div>-->
<!--                                <div class="counter__card__text">-->
<!--                                    <h4>{{ counter.count }}</h4>-->
<!--                                    <p>{{ counter.label }}</p>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="min-height-400">
                    <apexchart ref="analyticChart" height="400" :options="chartOptions" :series="series"></apexchart>
                    <!--                                <img src="/assets/img/Graph1.png" alt="" class="w-100 img-fluid">-->
                </div>
        </template>
    </section>
</template>
<script>
import client from '../../app/api/Client';
import {mapState} from 'vuex';
import {SWITCHED_TO_ANOTHER_ACCOUNT, SWITCHED_TO_ANOTHER_LANGUAGE} from '../../constants/events';
import {EventBus} from '../../extra/event-bus';
import DashboardStatsLoader from "../_loaders/DashboardStatsLoader";
import VueApexCharts from "vue-apexcharts";
import {analyticChartOptions} from "../../config/options";
import AnalyticsFilter from "../dropdown/AnalyticsFilter";
export default {
    components: {
        DashboardStatsLoader,
        AnalyticsFilter,
        apexchart: VueApexCharts,
    },
    data() {
        return {
            isContentLoading: true,
            showAnalytics: false,
            countFor: `Last 7 days`,
            series: [],
            counters: [],
            chartOptions: {
                ...analyticChartOptions,
                xaxis: {
                    ...analyticChartOptions.xaxis,
                    categories: [],
                    labels: {
                        ...analyticChartOptions.xaxis.labels,
                    },
                }
            },
        }
    },
    computed: {
        ...mapState(['company', 'language']),
        sections() {
            return Object.keys(this.stats);
        },
        analytics() {
            return this.showAnalytics;
        }
    },
    methods: {
        filter(val) {
          this.countFor = val.name;
          this.getAnalytics(val.params);
        },
        async getAnalytics(params =null) {
            this.isContentLoading = true;
            try {
                let {data: {data}} = await client().get('/company/analytics',{params: params});
                this.series = data.chart_data.map(chart => {
                    chart.name = this.$t(chart.name);
                    return chart;
                });
                this.chartOptions.xaxis.categories = data.categories;
                this.counters = data.counter_data;
                this.showAnalytics = true;

            } catch (e) {
            }
            this.isContentLoading = false;
        }
    },
    async mounted() {
        EventBus.$on(SWITCHED_TO_ANOTHER_ACCOUNT, (accountType) => {
            if (accountType === 'company') {
                this.getAnalytics();
            }
        })

        EventBus.$on(SWITCHED_TO_ANOTHER_LANGUAGE, (lang) => {
            this.getAnalytics();

        });
    },
    created() {
        this.getAnalytics()
    },
    beforeDestroy() {
        EventBus.$off(SWITCHED_TO_ANOTHER_ACCOUNT);
        EventBus.$off(SWITCHED_TO_ANOTHER_LANGUAGE);
    }
}
</script>
<style scoped>
    .analytic-section {
        background: #fff;
        padding: 25px 20px;
        border-radius: 10px;
    }
    .analytic-section .section-title-wrap {
        margin-bottom: 20px;
    }
    .analytic-section .counter-box {
        margin-bottom: 20px;
    }
    .analytic-section .counter-box .counter__card {
        background: rgba(96, 206, 131, 0.075);
    }
    .graph__control {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-columns: repeat( 5, 1fr );
        grid-gap: 10px;
    }
    .graph__control .graph__control__button {
        height: auto !important;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding: 15px;
        border: 1px solid transparent;
        background: #f5f7fd;
        border-radius: 5px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.03);
    }
    .count__for {
        color: #7d8091;
    }

    @media screen and (max-width: 1199px) {
        .graph__control {
            -ms-grid-columns: 1fr 1fr 1fr;
            grid-template-columns: repeat( 3, 1fr );
        }
    }
    @media all and (max-width: 767px) {
        .analytic-section {
            padding: 20px 15px;
        }
    }
    @media screen and (max-width: 575px) {
        .graph__control .graph__control__button {
            padding: 10px;
        }
        .count__for {
            display: none;
        }
        .graph__control {
            -ms-grid-columns: 1fr 1fr;
            grid-template-columns: repeat( 2, 1fr );
        }
    }
    @media screen and (max-width: 439px) {
        .graph__control {
            font-weight: 400;
        }
        .graph__control .count__title {
            font-size: 11px;
        }
        .counter__box .count__number {
            font-size: 14px;
        }
    }
</style>
