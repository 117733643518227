<template>
    <div class="dropdown pipeline-action" v-on-click-away="hideLanguage">
        <a href="javascript:void(0)"  @click="togglePage()" class="button white-button dropdown-toggle text-capitalize" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ $t(selectedType.name) }}
        </a>
        <div class="dropdown-menu dropdown-menu-left" :class="{ 'show' : listOpen }">
            <a class="dropdown-item text-capitalize" v-for="type in filterType" href="javascript:void(0)" @click="filterAnalytics(type)">
                {{ $t(type.name) }}
            </a>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                listOpen: false,
                selectedFilter : {
                    name : `Last 7 days`,
                    params : {
                        period: 'day',
                        noOfDays: 'last7'
                    }
                }
            }
        },
        computed: {
            filterType() {
                return [
                    {
                        name : `Last 7 days`,
                        params : {
                            period: 'day',
                            noOfDays: 'last7'
                        }
                    },
                    {
                        name : `Last 10 days`,
                        params : {
                            period: 'day',
                            noOfDays: 'last10'
                        }
                    },
                    {
                        name : `Last 30 Days`,
                        params : {
                            period: 'day',
                            noOfDays: 'last30'
                        }
                    },
                    {
                        name : `6 Months`,
                        params : {
                            period: 'month',
                            noOfDays: 'last6'
                        }
                    },
                    {
                        name : `1 Year`,
                        params : {
                            period: 'month',
                            noOfDays: 'last12'
                        }
                    }
                ]
            },
            selectedType() {
                return this.selectedFilter;
            }
        },
        methods : {
            filterAnalytics(type) {
                this.selectedFilter = type;
                this.$emit('filter', this.selectedFilter);
                this.listOpen = !this.listOpen;
            },
            changeJob(routeName) {
              this.$router.push({
                  name : routeName
              });
            },

            togglePage() {
                this.listOpen = !this.listOpen;
            },
            hideLanguage () {
                this.listOpen = false;
            }
        },
        mounted () {
            this.popupItem = this.$el;
        },
    }
</script>
<style scoped>

.white-button {
    background: #f5f7fd !important;
}
</style>